import React from "react";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
    svgHover: {
        fill: theme.palette.foreground.default,
        "&:hover": {
            fill: theme.palette.primary.main,
        },
        transition: "all 0.5s ease",
    },
}));

export const Logo = () => {
    const classes = useStyles();

    return (

        <svg t="1689656050374" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="1689" width="30" height="30"><path d="M938.855808 638.776382l0 270.299169c0 27.41028-22.210861 49.634444-49.621141 49.634444l-754.442728 0c-27.41028 0-49.647747-22.224164-49.647747-49.634444L85.144192 638.776382c0-27.41028 22.224164-49.634444 49.634444-49.634444s49.634444 22.224164 49.634444 49.634444l0 220.664725 655.17384 0L839.58692 638.776382c0-27.41028 22.224164-49.634444 49.634444-49.634444S938.855808 611.366102 938.855808 638.776382zM476.55165 701.027168c9.335622 9.534144 22.116717 14.905478 35.46063 14.905478 13.344936 0 26.121937-5.371334 35.461653-14.905478l198.014866-202.167442c19.179828-19.583011 18.85544-51.006697-0.732687-70.190619-19.587104-19.175735-51.016931-18.85544-70.196759 0.731664l-112.924909 115.285676L561.634444 114.924449c0-27.41028-22.224164-49.634444-49.634444-49.634444-27.41028 0-49.634444 22.224164-49.634444 49.634444l0 429.754834L349.473393 429.40077c-19.179828-19.583011-50.590212-19.902282-70.186526-0.731664-19.583011 19.179828-19.910469 50.603515-0.730641 70.190619L476.55165 701.027168z" p-id="1690"></path></svg>

    );
};
