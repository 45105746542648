import React from 'react';
import { LogoLink } from '../components/logo/LogoLink';
import { Content } from '../components/content/Content';
import { Hidden } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import DisplacementSphere from '../components/background/DisplacementSphere';
import { ThemeToggle } from '../components/theme/ThemeToggle';
import { SocialIcons } from '../components/content/SocialIcons';
import { SpeedDials } from '../components/speedDial/SpeedDial';

const useStyles = makeStyles(() => ({
  section: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100vh',
  }
}));

export const Home = () => {
  const classes = useStyles();

  return (
    <>
      <header className='header'>
        <div className='content' >
          <div> <a href="#about">About</a></div>
          <div><a href="#education">Education</a></div>
          <div><a href="#project">Project</a></div>
          <div><a href="#work">Work</a></div>
        </div>
      </header>
      <div className={classes.section}>
        <DisplacementSphere />
        <LogoLink />
        <Content />
        <ThemeToggle />
        <Hidden smDown>
          <SocialIcons />
        </Hidden>
        <Hidden mdUp>
          <SpeedDials />
        </Hidden>

      </div>

      <div id="about" className="subSuction">
        <div className='left'>
          <img src="https://prompterhub.oss-us-east-1.aliyuncs.com/Screenshot%202023-06-29%20at%202.27.46%20PM.png" alt="9d974993c3180d6dd426e67c80129def.png" border="0" />
        </div>
        <div className='right'>
          <div className='title'>About me</div>
          <div className='description'>Hello, I'm Zhen. I am currently pursuing a master's degree and working as a Research Assistant. My skill set includes strong proficiency in mobile application development and web application development. Additionally, I possess expertise in Python and am well-versed in machine learning, with a focus on frameworks like TensorFlow and PyTorch. I am actively seeking opportunities to further my academic journey by pursuing a Ph.D.</div>
          <div className='itemWrap'  >
            <div className='item' >
              <div className='icon'>
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" aria-hidden="true" class="h-5 w-5 text-white"><path stroke-linecap="round" stroke-linejoin="round" d="M9 6.75V15m6-6v8.25m.503 3.498l4.875-2.437c.381-.19.622-.58.622-1.006V4.82c0-.836-.88-1.38-1.628-1.006l-3.869 1.934c-.317.159-.69.159-1.006 0L9.503 3.252a1.125 1.125 0 00-1.006 0L3.622 5.689C3.24 5.88 3 6.27 3 6.695V19.18c0 .836.88 1.38 1.628 1.006l3.869-1.934c.317-.159.69-.159 1.006 0l4.994 2.497c.317.158.69.158 1.006 0z"></path></svg>
              </div>
              <div className='title'>
                Location:
              </div>
              <div className='content'>
                Indianapolis, IN
              </div>
            </div>
            <div className='item' >
              <div className='icon'>
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" aria-hidden="true" class="h-5 w-5 text-white"><path stroke-linecap="round" stroke-linejoin="round" d="M6.75 3v2.25M17.25 3v2.25M3 18.75V7.5a2.25 2.25 0 012.25-2.25h13.5A2.25 2.25 0 0121 7.5v11.25m-18 0A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75m-18 0v-7.5A2.25 2.25 0 015.25 9h13.5A2.25 2.25 0 0121 11.25v7.5"></path></svg>
              </div>
              <div className='title'>
                Age:
              </div>
              <div className='content'>
                27
              </div>
            </div>
            <div className='item' >
              <div className='icon'>
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" aria-hidden="true" class="h-5 w-5 text-white"><path stroke-linecap="round" stroke-linejoin="round" d="M9.813 15.904L9 18.75l-.813-2.846a4.5 4.5 0 00-3.09-3.09L2.25 12l2.846-.813a4.5 4.5 0 003.09-3.09L9 5.25l.813 2.846a4.5 4.5 0 003.09 3.09L15.75 12l-2.846.813a4.5 4.5 0 00-3.09 3.09zM18.259 8.715L18 9.75l-.259-1.035a3.375 3.375 0 00-2.455-2.456L14.25 6l1.036-.259a3.375 3.375 0 002.455-2.456L18 2.25l.259 1.035a3.375 3.375 0 002.456 2.456L21.75 6l-1.035.259a3.375 3.375 0 00-2.456 2.456zM16.894 20.567L16.5 21.75l-.394-1.183a2.25 2.25 0 00-1.423-1.423L13.5 18.75l1.183-.394a2.25 2.25 0 001.423-1.423l.394-1.183.394 1.183a2.25 2.25 0 001.423 1.423l1.183.394-1.183.394a2.25 2.25 0 00-1.423 1.423z"></path></svg>
              </div>
              <div className='title'>
                Intrest:
              </div>
              <div className='content'>
                Cycling
              </div>
            </div>
            <div className='item' >
              <div className='icon'>
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" aria-hidden="true" class="h-5 w-5 text-white"><path stroke-linecap="round" stroke-linejoin="round" d="M4.26 10.147a60.436 60.436 0 00-.491 6.347A48.627 48.627 0 0112 20.904a48.627 48.627 0 018.232-4.41 60.46 60.46 0 00-.491-6.347m-15.482 0a50.57 50.57 0 00-2.658-.813A59.905 59.905 0 0112 3.493a59.902 59.902 0 0110.399 5.84c-.896.248-1.783.52-2.658.814m-15.482 0A50.697 50.697 0 0112 13.489a50.702 50.702 0 017.74-3.342M6.75 15a.75.75 0 100-1.5.75.75 0 000 1.5zm0 0v-3.675A55.378 55.378 0 0112 8.443m-7.007 11.55A5.981 5.981 0 006.75 15.75v-1.5"></path></svg>
              </div>
              <div className='title'>
                Study:
              </div>
              <div className='content'>
                Indiana University-Purdue University Indianapolis
              </div>
            </div>
            <div className='item' >
              <div className='icon'>
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" aria-hidden="true" class="h-5 w-5 text-white"><path stroke-linecap="round" stroke-linejoin="round" d="M2.25 21h19.5m-18-18v18m10.5-18v18m6-13.5V21M6.75 6.75h.75m-.75 3h.75m-.75 3h.75m3-6h.75m-.75 3h.75m-.75 3h.75M6.75 21v-3.375c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125V21M3 3h12m-.75 4.5H21m-3.75 3.75h.008v.008h-.008v-.008zm0 3h.008v.008h-.008v-.008zm0 3h.008v.008h-.008v-.008z"></path></svg>
              </div>
              <div className='title'>
                Employment:
              </div>
              <div className='content'>
                <a style={{ color: 'black' }} href='https://www.thecn.com/' >COURSENETWORKING</a>
              </div>
            </div>

            <div className='item' >
              <div className='icon'>
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" aria-hidden="true" class="h-5 w-5 text-white"><path stroke-linecap="round" stroke-linejoin="round" d="M3 3v1.5M3 21v-6m0 0l2.77-.693a9 9 0 016.208.682l.108.054a9 9 0 006.086.71l3.114-.732a48.524 48.524 0 01-.005-10.499l-3.11.732a9 9 0 01-6.085-.711l-.108-.054a9 9 0 00-6.208-.682L3 4.5M3 15V4.5"></path></svg>
              </div>
              <div className='title'>
                Nationality:
              </div>
              <div className='content'>
                China
              </div>
            </div>
          </div>
        </div>
      </div>


      <div id="education" className="subSuction" >
        <div className='left'>
          <div className='title' >Education</div>
        </div>
        <div className='right'>
          <div className='list'>
            <div className='name'>Purdue School of Engineering & Technology - IUPUI</div>
            <div className='detail'>Master • May 2024 • Indianapolis </div>
            <div className='describeWrap'>
              <div className='describe' >Applied Data Management and Analytics</div>
            </div>
          </div>

          <div className='list' style={{ marginTop: "30px" }}>
            <div className='name'>Henan University</div>
            <div className='detail'>Bachelor • Jun 2019 • China </div>
            <div className='describeWrap'>
              <div className='describe' >E-Communication Engineering</div>
            </div>
          </div>

        </div>
      </div>
      <div className="subSuction" >
        <div className='left'>
          <div className='title' >Skills</div>
        </div>
        <div className='right'>
          <div className='list'>
            <div className='skillsDetail'>
              <div className='subtitle'>Skills:</div> JavaScript, HTML, CSS, React, Vue, Next, Sass/Less, Node, Koa, Express, Strapi, Egg, Webpack, Docker, Git.</div>
            <div className='skillsDetail'>
              <div className='subtitle'>Applications:</div>  Admin Dashboard, Hybrid Application, Multi-end App(js), Web Application, Static Website, Node Server Application.</div>
          </div>
        </div>
      </div>

      <div id="project" className="subSuction" >
        <div className='left'>
          <div className='title' >Project</div>
        </div>
        <div className='right'>
          <div className='list'>
            <div className='name'>TIC APP</div>
            <div className='detail'> Full stack development </div>
            <div className='describeWrap'>
              <div className='describe' >TIC APP is a multi-platform application developed using Vue and Strapi, encompassing features like typing exercises, goal setting, error tracking, customized practice vocabulary, and animated feedback. It includes  <a href='https://apps.apple.com/cn/app/tic-app/id6443509306' > iOS</a> and <a href='https://play.google.com/store/apps/details?id=uni.UNI4DF4E5E' >Android</a>  versions. Testing account: houzhen123, password: 123456.</div>
              <ul>
                <li>Developed a Chinese typing keyboard enhance typing practice, featuring precise input without fuzzy search.</li>
                <li>Developed a feature for customizing Chinese vocabulary for practice and implemented a pipeline that automatically generates contextual dialogues, pinyin, pronunciation, and translations for the vocabulary using AI tools.</li>
                <li>Implemented an error detection feature to identify various types of user typing mistakes, accompanied by CSS3 animations for error reminders. Celebratory fireworks effects using Lottie.js are displayed upon successful completion.</li>
                <li>Enhanced the gamification of the review process by implementing a typing game similar to shooting planes using Vue and CSS animations. When input is correct, the planes disappear and keep track of successful and unsuccessful vocabulary.</li>
              </ul>
            </div>
          </div>
          <div className='list'>
            <div className='name'>Prompter Hub </div>
            <div className='detail'> Full stack development </div>
            <div className='describeWrap'>
              <div className='describe' > <a href='https://dev.prompterhub.com'>Prompter Hub</a> is an advanced ChatGPT tuning tool developed using React and Node. It incorporates a drag-and-drop layout, project management, model fine-tuning, synchronized generation of multiple responses, and a web editor with a local database. Additionally, it offers support for drag-and-drop flowchart building, enabling seamless prompt switching. <a href='https://github.com/hzvictor/prompthub2'>Code Link</a> </div>
              <ul>
                <li>Using dexie.js to manipulate indexDB, I have created 13 tables to cached all user's data locally in the browser. This local caching approach significantly enhances the user experience of the application.</li>
                <li>Developed multiple components to enhance maintainability, including a multi-tag React component with editable and draggable sorting capabilities, as well as an editable draggable sorting table component, significantly boosting system functionality.</li>
                <li>Developed a functionality to convert flowcharts into executable code, transforming all nodes of the flowcharts into JavaScript code. Transformed flowchart data into a relationship 'tree' and a node functionalities 'map', enabling execution of the flowcharts by following the corresponding code based on the tree structure.</li>
                <li>Increase code execution efficiency and ensure main process safety by utilizing Web Workers to execute flowchart code within a sandbox environment.</li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <div id="work" className="subSuction" >
        <div className='left'>
          <div className='title' >Work</div>
        </div>
        <div className='right'>
          <div className='list'>
            <div className='name'>CourseNetworking</div>
            <div className='detail'> Full stack development  • 2021-11 - Today </div>
            <div className='describeWrap'>
              <div className='describe' >During my graduate studies, I was awarded a full scholarship by CourseNetworking, where I actively contributed to the development of CourseNetworking (https://www.thecn.com/). I played a crucial role in enhancing website accessibility and developing various web-based instructional tools to improve its functionality.</div>
              <ul>
                <li>Developed a student assignment grading tool, enabling teachers to conveniently view, grade, and annotate student assignments, resulting in improved evaluation efficiency and student engagement.</li>
                <li>Implemented best practices and adhered to WCAG standards to enhance website accessibility. Integrated accessibility features such as alt text for images, proper heading structure, and navigation, ensuring inclusivity for all users.</li>
                <li>Developed a fully-featured drawing board application using Fabric.js and Vue, offering a wide range of drawing tools, undo/redo functionality, and the ability to save drawings.</li>
                <li>Developed a responsive Single Sign-On (SSO) page using Vue, allowing institutions to configure different types of login and registration methods through the backend.</li>
                <li>Developed a functionality using the ChatGPT API to extract key keywords from user posts and convert them into hashtags, while also assisting teachers in designing course content.</li>
              </ul>
            </div>
          </div>

          <div className='list' style={{ marginTop: "30px" }}>
            <div className='name'>Hilink</div>
            <div className='detail'>Full stack development • 2020-08 - 2021-09</div>
            <div className='describeWrap'>
              <div className='describe' >Led a 4-person development team to build an online education application from scratch, including users, live broadcast, learning materials, schedule and multilingual. The team uses React.js to develop the website, and uses Egg.js, MySQL and Redis to build back-end services, and hosting on alibaba cloud ecs.</div>
              <div className='subTitle' >Frontend</div>
              <ul>
                <li>Based on jquery and using webpack to build a static multi-page website, the single-page resource is reduced to about 20kb.</li>
                <li>Optimize the resource introduction sequence of the project, optimize the code that hinders loading, and use CDN for the entire site resources to reduce the website loading speed to less than three seconds.</li>
                <li>Based on the Agora network service, the project has the capabilities of audio and video live broadcast, video recording and backup, real-time monitoring, and screen sharing.</li>
                <li>Develop a schedule entry component to enable teachers and students to arrange their courses more intuitively.</li>
              </ul>
              <div className='subTitle' >Backend</div>
              <ul>
                <li>The user center is designed and developed to meet both 2B and 2C service types, and to realize account services with different functions including teachers, students, principals, and head teachers.</li>
                <li>Designing the class scheduling system, in order to realize the rendering schedule list for teachers and students in different time zones and more convenient to prepare, the time division is used, and the 48-base model efficiently meets the product needs.</li>
                <li>Develop the teacher wallet function, statistically record and modify the teacher's salary for each class, seven-day delay in salary payment, withdrawal application and other functions.</li>
              </ul>
            </div>
          </div>

          <div className='list' style={{ marginTop: "30px" }}>
            <div className='name'>360</div>
            <div className='detail'>Front-end development • 2019-03 - 2020-08</div>
            <div className='describeWrap'>
              <div className='describe' >Mainly responsible for the development of the H5 part of RisApp and part of the Admin background requirements.</div>
              <div className='subTitle' >RisApp （The RisApp project, a short video project in Latin America. ）</div>
              <ul>
                <li>To solve the problem of sharing fission, the page package is large. By re-integrating the off-site sharing projects, the single page is changed to a multi-page price structure, and the opening speed of a single page is significantly improved.</li>
                <li>Responsible for the company's fission project development, including the use of Echarts to visualize the epidemic page, the use of canvas and cocos to develop a series of animated mini games, the lucky draw treasure box activity, and a series of fission sharing pages such as selected video sharing.</li>
              </ul>
              <div className='subTitle' >Admin background</div>
              <ul>
                <li>Refactor classification and sink business components, and build custom React UI components, including filtering, video display, and item list components, to speed up front-end development efficiency.</li>
              </ul>
            </div>
          </div>

        </div>
      </div>

      <div className="end"  >
        © Copyright 2023 <a className="hrefblack" href='https://github.com/JoHoop' >Github</a>
      </div>
    </>
  );
};
